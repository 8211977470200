import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Alvearie/alvearie.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import ObjectivesSection from 'gatsby-theme-carbon/src/templates/ObjectivesSection';
import GetInvolvedSection from 'gatsby-theme-carbon/src/templates/GetInvolvedSection';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
Project Alvearie
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
        <h2>
Project Alvearie is an open-source project designed to bring together contributors from across the health and life
sciences ecosystem for collaboration around common and pervasive challenges to health data ingestion and flow.
        </h2>
        <p>
Together, we're working toward building a set of extensible, modular and scalable multi-cloud data and analytics components
that can help organizations improve care delivery and operate more efficiently in a highly regulated environment.
        </p>
        <p>
          <span style={{
            'font-weight': 'bold'
          }}>Alvearie:</span> <span style={{
            'font-style': 'italic'
          }}>(al-vee-air-ee)</span>  - from Latin alvearium, "beehive"<br /> 
          <span style={{
            'font-style': 'italic'
          }}>
  Represents a group of people collaborating to build something that any one of us couldn't do on our own. In the sense of a repository - 
  especially of knowledge or information: originally the name of a dictionary encompassing several languages
          </span>
        </p>
      </Column>
    </Row>
    <ObjectivesSection mdxType="ObjectivesSection" />
    <Row mdxType="Row">
      <Column colMd={4} colLg={6} noGutterMdLeft mdxType="Column">
        <GetInvolvedSection mdxType="GetInvolvedSection" />
      </Column>
      <Column colMd={4} colLg={6} noGutterMdLeft mdxType="Column">
        <div style={{
          'border-left': '1px solid #aaaaaa',
          'padding-left': '20px'
        }}>
          <h2 style={{
            'padding-bottom': '20px'
          }}>Open Source Community Projects</h2>
          <p>{`Alvearie combines a number of open source projects:`}</p>
          <p style={{
            'padding-left': '20px',
            'line-height': '40px',
            'padding-top': '20px'
          }}>
OpenShift hybrid cloud platform<br />
Apache Spark cluster computing framework<br />
Apache Nifi data distribution and processing<br />
Apache Kafka stream-processing platform<br />
Apache Camel integration framework<br />
Apache Flink batch-processing framework<br />
Kubeflow for machine learning workflows on kubernetes<br />
Keycloak for identity and access management<br />
NATS distributed messaging queue<br />
dcm4che applications and utilites<br />
Mirth HL7 interface engine<br />
HAPI utilities for HL7 v2 messaging<br />
CQL Evaluation Engine<br />
          </p>
        </div>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      